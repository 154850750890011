import React from "react";
import landing from "../../assets/landing.png";

const HeroSection = () => {
  return (
    <>
      <div className="mt-24 sm:mt-20 md:mt-20 lg:mt-16 xl:mt-16 2xl:mt-16 p-2 sm:p-5 md:p-7 lg:p-10 xl:p-10 2xl:p-10 text-white flex justify-center h-fit flex-col ">
        <div className="relative z-10 bg-gradient-to-tl  from-purple-500 to-purple-800 rounded-2xl h-[20rem] sm:h-[25rem] md:h-[30rem] lg:h-[43.75rem] xl:h-[43.75rem] 2xl:h-[43.75rem]  w-full flex justify-center flex-col">
          <img
            data-aos="fade-left"
            className="w-full  h-full relative object-cover left-16 sm:left-36 md:left-44 lg:left-56 xl:left-56 2xl:left-56 "
            src={landing}
            alt="man"
            loading="lazy"
            width="1000"
            height="667"
          />
          <div className="  p-5 sm:p-0 md:p-0 lg:p-0 xl:p-0 2xl:p-0 sm:ml-6 md:ml-10 lg:ml-16 xl:ml-16 2xl:ml-16  absolute z-10 w-fit flex flex-col justify-between gap-8">
            <div className=" font-semibold vertical-text text-2xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-6xl 2xl:text-6xl">
              <div className="mt-2">Elevating</div>
              <div className="mt-2">Brands with</div>
              <div className="mt-2">Cutting-Edge</div>
              <div className="mt-2">Digital Influence</div>
              <div className="mt-2">Solutions</div>
            </div>
            <div className=" opacity-70 text-xs sm:text-base md:text-lg lg:text-xl xl:text-xl 2xl:text-xl">
              Empowering brands through innovative <br /> digital strategies,
              <br />
              maximizing online influence.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
