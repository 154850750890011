import React from "react";
import { useState } from "react";
import { FaXmark } from "react-icons/fa6";

const AsBrand = () => {
  const [user, setUser] = useState({
    name: "",
    email: "",
    mobile: "",
    agency: "",
    budget: "",
    details: "",
  });

  const [showPopup, setShowPopup] = useState(false);

  let id, value;
  const handleInputs = (e) => {
    id = e.target.id;
    value = e.target.value;
    setUser({ ...user, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform form submission logic
    const formData = new FormData();
    formData.append("Name", user.name);
    formData.append("Email", user.email);
    formData.append("Mobile", user.mobile);
    formData.append("Agency", user.agency);
    formData.append("Budget", user.budget);
    formData.append("Details", user.details);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_GOOGLE_CALLBACK_URI}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        setShowPopup(true);
        // // Redirect to localhost:3000
        // window.location.href = "http://localhost:3000";
        setUser({
          name: "",
          email: "",
          mobile: "",
          agency: "",
          budget: "",
          details: "",
        });
      } else {
        // Handle error
        console.error("Form submission failed");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const closePopup = () => {
    setShowPopup(false);
    // Redirect to localhost:3000 after closing popup (optional)
    window.location.href = "https://vendymedia.com";
  };

  return (
    <>
      <div className="">
        <form
          method="POST"
          className="space-y-8  p-8  w-[26rem]"
          // action="https://script.google.com/macros/s/AKfycbw6ojo5RuBJX1Urgd7gHPfeNHZxrfYbrIFrNHfRFbgBaccfYSQD1KyUFMb9dutpM_lg/exec"
          onSubmit={handleSubmit}
        >
          <div>
            <input
              type="text"
              name="Name"
              id="name"
              value={user.name}
              onChange={handleInputs}
              className="focus:ring-purple-500 focus:border-purple-500 block p-5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              placeholder="What's Your Name"
              required
            />
          </div>
          <div>
            <input
              type="email"
              name="Email"
              id="email"
              value={user.email}
              onChange={handleInputs}
              className="focus:ring-purple-500 focus:border-purple-500 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              placeholder="What's Your Email"
              required
            />
          </div>
          <div>
            <input
              type="number"
              name="Mobile"
              value={user.mobile}
              onChange={handleInputs}
              id="mobile"
              className="focus:ring-purple-500 focus:border-purple-500 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              placeholder="What's Your Mobile Number"
              required
            />
          </div>
          <div>
            <input
              type="name"
              name="Agency"
              id="agency"
              value={user.agency}
              onChange={handleInputs}
              className="focus:ring-purple-500 focus:border-purple-500 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              placeholder="What's Your Company Name"
              required
            />
          </div>
          <div>
            <input
              type="message"
              name="Budget"
              value={user.budget}
              onChange={handleInputs}
              id="budget"
              className="focus:ring-purple-500 focus:border-purple-500 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              placeholder="What's Your Budget"
              required
            />
          </div>
          <div>
            <input
              type="message"
              name="Details"
              value={user.details}
              onChange={handleInputs}
              id="details"
              className="focus:ring-purple-500 focus:border-purple-500 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              placeholder="Briefly Explain Your Details"
              required
            />
          </div>
          <button
            type="submit"
            className="button font-medium w-full h-12 rounded-lg text-sm px-10 py-3 me-2 mb-2 bg-gradient-to-tl  from-purple-500 to-purple-800   text-center text-white  bg-primary-700  hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 "
          >
            REQUEST CALLBACK
          </button>
        </form>
      </div>
      {/* Modal Popup */}
      {showPopup && (
        <div className="popup-overlay fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none">
          <div className="popup-content relative w-96 bg-green-50 rounded-lg shadow-lg focus:outline-none">
            <button
              className="close-button absolute top-0 right-0 m-4 text-gray-500 hover:text-gray-800"
              onClick={closePopup}
            >
              <FaXmark
                className=""
                style={{
                  fontSize: "medium",
                  fontWeight: "light",
                  border: "1.5px solid red",
                  borderRadius: "100%",
                  color: "red",
                  padding: "1",
                  width: "20px",
                  height: "20px",
                }}
              />
            </button>
            <div className="p-8 popup-text">
              <h2 className="text-lg font-medium text-green-700 mb-4">
                Callback Request Submitted
              </h2>
              <p className="text-sm text-gray-600">
                Thank you for submitting your callback request. We will get back
                to you shortly.
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AsBrand;
