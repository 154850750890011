import React from "react";
import NavBar from "./Components/navbar";
import HeroSection from "./Components/heroSection";
import Footer from "./Components/footer";
import Aboutus from "./Components/aboutUs";
import Chooseus from "./Components/chooseUs";
import Brands from "./Components/brands";
import Services from "./Components/services";
import Influencers from "./Components/influencerTalent";
import StayOrganised from "./Components/chooseUs/stayOrganised";
import Connect from "./Components/connect";

const App = () => {
  return (
    <>
      <div className="max-w-screen-2xl overflow-hidden">
        <NavBar />

        <HeroSection />
        <Aboutus />
        <Chooseus />
        <StayOrganised />
        <Brands />
        <Services />
        {/* <Influencers /> */}
        <Connect />
        <Footer />
      </div>
    </>
  );
};

export default App;
