import React from "react";
import { FaGlobe } from "react-icons/fa";
import { FaHeadSideVirus } from "react-icons/fa6";
import { FaUsers } from "react-icons/fa6";
import { FaRocket } from "react-icons/fa";

const Aboutus = () => {
  return (
    <div
      id="about"
      className=" px-10 text-white flex justify-between text-xl  py-3 my-16 mb-8 gap-10 sm:gap-10 md:gap-10 lg:gap-2 xl:gap-2 2xl:gap-2 flex-col sm:flex-col md:flex-col lg:flex-row xl:flex-row 2xl:flex-row"
    >
      <div className=" flex justify-center flex-col w-full md:p-4 lg:p-4 xl:p-4 2xl:p-4 sm:w-full md:w-full lg:w-8/12 xl:w-8/12 2xl:w-8/12">
        <div className="text-purple-500 text-base font-semibold">OUR STORY</div>
        <div className="font-semibold mt-7 text-gray-800 text-5xl sm:text-6xl md:text-6xl lg:text-6xl xl:text-6xl 2xl:text-6xl">
          We Work With Leading International Brands <br /> And Businesses
        </div>
        <div className="mt-5 mb-5 text-base text-gray-400">
          We specialize in collaborating with renowned <br /> international
          brands for mutual success.
        </div>
        <a
          href="#connect"
          className="text-white button bg-gradient-to-tl flex items-center justify-center from-purple-500 to-purple-800 max-w-48 h-12 focus:ring-4 focus:ring-pink-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-purple-800 hover:bg-purple-600 "
        >
          <div> CONTACT US </div>
        </a>
      </div>
      <div className=" py-4 flex flex-col justify-between gap-5 sm:gap-5 md:gap-5 lg:gap-0 xl:gap-0 2xl:gap-0 sm:p-0 md:p-0 lg:p-2 xl:p-2 2xl:p-2 w-full sm:w-full md:w-full lg:w-4/12 xl:w-4/12 2xl:w-4/12">
        <div className=" flex flex-row items-center gap-4">
          <div className="icons mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl bg-primary">
            <div style={{ border: "1.5px solid #8A2BE2", borderRadius: "50%" }}>
              <FaGlobe
                className="icon1"
                style={{
                  color: "#8A2BE2",
                  padding: "20px",
                  width: "70px",
                  height: "70px",
                }}
              />
            </div>
          </div>

          <div className="font-bold text-gray-800  text-2xl">
            Global Reach <br />
            <div className="font-light text-sm text-gray-400">
              Access worldwide markets through partnerships with leading global
              businesses.
            </div>
          </div>
        </div>
        <div className=" flex flex-row items-center gap-4">
          <div className="icons mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl bg-primary">
            <div style={{ border: "1.5px solid #8A2BE2", borderRadius: "50%" }}>
              <FaRocket
                className="icon1"
                style={{
                  color: "#8A2BE2",
                  padding: "20px",
                  width: "70px",
                  height: "70px",
                }}
              />
            </div>
          </div>

          <div className="font-bold text-gray-800 text-2xl">
            Innovative Solutions <br />
            <div className="font-light text-sm text-gray-400">
              Offer cutting-edge strategies tailored to international brand
              needs.
            </div>
          </div>
        </div>
        <div className=" flex flex-row items-center gap-4">
          <div className="icons mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl bg-primary">
            <div style={{ border: "1.5px solid #8A2BE2", borderRadius: "50%" }}>
              <FaUsers
                className="icon1"
                style={{
                  color: "#8A2BE2",
                  padding: "20px",
                  width: "70px",
                  height: "70px",
                }}
              />
            </div>
          </div>

          <div className="font-bold text-gray-800  text-2xl">
            Strategic Collaboration <br />
            <div className="font-light text-sm text-gray-400">
              Collaborate closely with top international brands to achieve
              shared objectives.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
