import React, { useState } from "react";
import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";

const Chooseus = () => {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <>
      <div
        data-aos="fade-left"
        className=" px-10 items-center text-white flex justify-between text-xl flex-col sm:flex-col md:flex-col lg:flex-row xl:flex-row 2xl:flex-row py-3 my-32 mb-8 gap-10 sm:gap-10 md:gap-10 lg:gap-2 xl:gap-2 2xl:gap-2"
      >
        <div className="items-center  w-[22rem] h-[20rem] sm:w-[37rem] sm:h-[32rem] md:w-[39rem] md:h-[32rem] lg:w-[39rem] lg:h-[32rem] xl:w-[39rem] xl:h-[32rem] 2xl:w-[39rem] 2xl:h-[32rem] flex justify-center flex-col">
          <img
            className="w-full h-full mx-auto object-cover rounded-2xl md:w-full md:h-full lg:w-full lg:h-full"
            src="https://tailus.io/sources/blocks/classic/preview/images/man.jpg"
            alt="man"
            loading="lazy"
          />
        </div>
        <div className="flex flex-col justify-center w-full sm:w-full md:w-full lg:w-7/12 xl:w-7/12 2xl:w-7/12  sm:px-0 md:px-0 lg:px-8 xl:px-8 2xl:px-8 sm:pl-0 md:pl-0 lg:pl-16 xl:pl-16 2xl:pl-16 ">
          <div className="text-purple-500 text-base font-semibold">
            WHY CHOOSE US ?
          </div>
          <div className="font-semibold text-5xl sm:text-6xl md:text-6xl lg:text-6xl xl:text-6xl 2xl:text-6xl mt-2 text-gray-800">
            Helping everyone worldwide make the most of social media power.
          </div>
          <div className="mt-4 mb-8 text-base  text-gray-400">
            Supporting users worldwide in optimizing their use of social media.
          </div>
          <ScrollTrigger
            onEnter={() => setCounterOn(true)}
            onExit={() => setCounterOn(false)}
          >
            <div className=" flex justify-between items-start sm:items-start md:items-start lg:items-center xl:items-center 2xl:items-center flex-row  p-0 sm:p-0 md:p-0 lg:p-2 xl:p-2 2xl:p-2">
              <div className="flex flex-col justify-between items-center p-2">
                <div className=" font-semibold text-purple-500 mb-1 text-3xl sm:text-5xl md:text-5xl lg:text-5xl xl:text-5xl 2xl:text-5xl">
                  {counterOn && (
                    <CountUp start={0} end={55} duration={3} delay={0} />
                  )}
                  k+
                </div>
                <div className="text-base text-gray-800">Influencer Talent</div>
              </div>
              <div className="flex flex-col justify-between items-center p-2">
                <div className="font-semibold text-purple-500 mb-1 text-3xl sm:text-5xl md:text-5xl lg:text-5xl xl:text-5xl 2xl:text-5xl">
                  {counterOn && (
                    <CountUp start={0} end={87} duration={3} delay={0} />
                  )}
                  +
                </div>
                <div className="text-base text-gray-800">Happy Client</div>
              </div>
              <div className="flex flex-col justify-between items-center p-2">
                <div className="font-semibold text-purple-500 mb-1 text-3xl sm:text-5xl md:text-5xl lg:text-5xl xl:text-5xl 2xl:text-5xl">
                  {counterOn && (
                    <CountUp start={0} end={232} duration={3} delay={0} />
                  )}
                  +
                </div>
                <div className="text-base text-gray-800">Project Success</div>
              </div>
            </div>
          </ScrollTrigger>
        </div>
      </div>
    </>
  );
};

export default Chooseus;
