import React from "react";
import { FaEnvelopeOpenText } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa6";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";

const Footer = () => {
  return (
    <>
      <div>
        <footer>
          <div className="mx-auto w-full ">
            <div className="bg-black px-5 py-7 gap-8 flex flex-col justify-between sm:flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row ">
              <div className="w-80 sm:w-80 md:w-96 lg:w-96 xl:w-96 2xl:w-96  md:pl-7 lg:pl-7 xl:pl-7 2xl:pl-7">
                <div className="mb-6 text-white text-lg md:text-lg font-light dark:text-white">
                  Palam Court, Mehrauli-Gurgaon Road, Sector 16, Gurugram,
                  Haryana, 122007
                </div>
              </div>
              <div className="flex  flex-row justify-between xl:ml-52 2xl:ml-52 gap-5 lg:w-96 xl:w-96 2xl:w-[40rem] 2xl:gap-10">
                <div className="flex w-full justify-start">
                  <ul className="text-white text-base dark:text-gray-400 font-light">
                    <li className="mb-4">
                      <a href="#" className="hover:underline">
                        FAQ'S
                      </a>
                    </li>
                    <li className="mb-4">
                      <a href="#" className="hover:underline">
                        Services
                      </a>
                    </li>
                    <li className="mb-4">
                      <a href="#" className="hover:underline">
                        Career
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="pt-5  mr-10 md:pt-0 lg:pt-0 xl:pt-0 2xl:pt-0 ">
                <h2 className="mb-6 text-white text-lg font-light dark:text-white">
                  <div className="flex flex-row items-center">
                    <FaEnvelopeOpenText style={{ marginRight: "7px" }} />
                    support@vendymedia.com
                  </div>
                  <div className="flex flex-row items-center">
                    <FaPhone style={{ marginRight: "7px" }} />
                    +91 8130002218
                  </div>
                </h2>
                <div className="flex flex-row items-center text-sm dark:text-gray-400 font-medium">
                  <a href="#" className="mr-2">
                    <FaFacebook className="text-white w-6 h-6 hover:text-purple-500" />
                  </a>
                  <a href="#" className="mr-2">
                    <FaSquareXTwitter className="text-white w-6 h-6 hover:text-purple-500" />
                  </a>
                  <a href="#" className="mr-2">
                    <FaLinkedin className="text-white w-6 h-6 hover:text-purple-500" />
                  </a>
                  <a
                    href="https://www.instagram.com/vendymedia/"
                    target="_blank"
                    className="mr-2"
                  >
                    <FaInstagram className="text-white w-6 h-6 hover:text-purple-500" />
                  </a>
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center py-6 bg-white dark:bg-gray-700">
              <span className="text-sm text-black dark:text-gray-300">
                © 2024 <a href="#">VendyMedia™</a>. All Rights Reserved.
              </span>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
