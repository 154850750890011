import React from "react";
import { BrowserRouter } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import logo from "../../assets/vendymedia.png";
import { FaBars } from "react-icons/fa";

const NavBar = () => {
  const handleClick = () => {
    window.location.href = "/";
  };

  return (
    <>
      <BrowserRouter>
        <div className="max-w-screen-2xl fixed z-50 bg-white w-full text-gray-800">
          <nav className="border-gray-200  px-7  dark:bg-gray-900 dark:border-gray-700">
            <div className=" flex flex-wrap items-center justify-between mx-auto p-4">
              <div
                className="flex flex-row justify-between items-center cursor-pointer"
                onClick={handleClick}
              >
                <img src={logo} alt="vendymedia logo" className="w-16" />
                <div className="acme-regular text-gray-800 font-bold text-3xl ml-2 sm:text-4xl md:text-5xl ">
                  VendyMedia
                </div>
              </div>
              <button
                data-collapse-toggle="navbar-dropdown"
                type="button"
                className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                aria-controls="navbar-dropdown"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 17 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 1h15M1 7h15M1 13h15"
                  />
                </svg>
              </button>
              <div
                className="hidden w-full md:block md:w-auto"
                id="navbar-dropdown"
              >
                <ul className="flex gap-5 mr-2  flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                  <li>
                    <Link
                      className="block py-2 px-3 text-white bg-purple-500 rounded md:bg-transparent md:text-purple-700 md:p-0 md:dark:text-purple-500 dark:bg-purple-600 md:dark:bg-transparent"
                      to="#"
                      smooth
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-purple-700 md:p-0 dark:text-white md:dark:hover:text-purple-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                      to="#about"
                      smooth
                    >
                      Our Story
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-purple-700 md:p-0 dark:text-white md:dark:hover:text-purple-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                      to="#"
                      smooth
                    >
                      Our Network
                    </Link>
                  </li> */}

                  <li>
                    <Link
                      className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-purple-700 md:p-0 dark:text-white md:dark:hover:text-purple-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                      to="#connect"
                      smooth
                    >
                      Let's Connect
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </BrowserRouter>
    </>
  );
};

export default NavBar;
