import React from "react";
import logo2 from "../../assets/dad.png";
import logo3 from "../../assets/pet.png";
import logo4 from "../../assets/fashionnova.png";
import logo5 from "../../assets/urme.png";
import logo7 from "../../assets/umax.png";
import logo8 from "../../assets/shmoody.png";
import logo9 from "../../assets/myntra.png";
import logo10 from "../../assets/Meesho.png";
import logo11 from "../../assets/amazon.png";
import logo12 from "../../assets/flipkart.png";
import logo13 from "../../assets/levi.png";
import logo14 from "../../assets/mama.png";
import logo15 from "../../assets/ceta.png";
import logo16 from "../../assets/plum.png";

const Brands = () => {
  return (
    <>
      <div className=" flex flex-col justify-between items-center py-8 gap-8">
        <h2 className="flex items-center justify-center w-full  px-8 font-semibold mt-2  text-gray-800  ">
          <div className="flex flex-col items-center justify-center text-[2rem] sm:text-5xl md:text-6xl lg:text-6xl xl:text-6xl 2xl:text-6xl">
            <div>Our</div>
            <div>Trusted Partners</div>
          </div>
        </h2>
        <div className="slider ">
          {" "}
          <div className="slide-track bg-gray-100 px-10  py-7 sm:py-10 md:py-10 lg:py-10 xl:py-10 2xl:py-10 w-full text-black flex justify-between items-center font-semibold text-xl">
            <img
              className="w-20 sm:w-28 md:w-28 lg:w-28 xl:w-28 2xl:w-28  "
              src={logo9}
              alt="logo9"
            />
            <img
              className="w-40 sm:w-48 md:w-48 lg:w-48 xl:w-48 2xl:w-48  "
              src={logo2}
              alt="logo2"
            />
            <img
              className="w-28 sm:w-44 md:w-44 lg:w-44 xl:w-44 2xl:w-44  "
              src={logo15}
              alt="logo15"
            />
            <img
              className="w-16 sm:w-28 md:w-28 lg:w-28 xl:w-28 2xl:w-28  "
              src={logo3}
              alt="logo3"
            />
            <img
              className="w-28 sm:w-44 md:w-44 lg:w-44 xl:w-44 2xl:w-44  "
              src={logo16}
              alt="logo16"
            />
            <img
              className="w-52 sm:w-72 md:w-72 lg:w-72 xl:w-72 2xl:w-72  "
              src={logo4}
              alt="logo4"
            />
            <img
              className="w-36 sm:w-52 md:w-52 lg:w-52 xl:w-52 2xl:w-52 "
              src={logo12}
              alt="logo12"
            />
            <img
              className="w-36 sm:w-52 md:w-52 lg:w-52 xl:w-52 2xl:w-52  "
              src={logo14}
              alt="logo14"
            />
            <img
              className="w-28 sm:w-44 md:w-44 lg:w-44 xl:w-44 2xl:w-44  "
              src={logo5}
              alt="logo5"
            />
            <img
              className="w-32 sm:w-52 md:w-52 lg:w-52 xl:w-52 2xl:w-52  "
              src={logo10}
              alt="logo10"
            />
            <img
              className="w-16 sm:w-28 md:w-28 lg:w-28 xl:w-28 2xl:w-28  "
              src={logo7}
              alt="logo7"
            />
            <img
              className="w-28 sm:w-44 md:w-44 lg:w-44 xl:w-44 2xl:w-44  "
              src={logo13}
              alt="logo13"
            />
            <img
              className="w-28 sm:w-44 md:w-44 lg:w-44 xl:w-44 2xl:w-44  "
              src={logo8}
              alt="logo8"
            />
            <img
              className="w-32 sm:w-48 md:w-48 lg:w-48 xl:w-48 2xl:w-48  "
              src={logo11}
              alt="logo11"
            />

            {/* Same 14 Images */}

            <img
              className="w-20 sm:w-28 md:w-28 lg:w-28 xl:w-28 2xl:w-28  "
              src={logo9}
              alt="logo9"
            />
            <img
              className="w-40 sm:w-48 md:w-48 lg:w-48 xl:w-48 2xl:w-48  "
              src={logo2}
              alt="logo2"
            />
            <img
              className="w-28 sm:w-44 md:w-44 lg:w-44 xl:w-44 2xl:w-44  "
              src={logo15}
              alt="logo15"
            />
            <img
              className="w-16 sm:w-28 md:w-28 lg:w-28 xl:w-28 2xl:w-28  "
              src={logo3}
              alt="logo3"
            />
            <img
              className="w-28 sm:w-44 md:w-44 lg:w-44 xl:w-44 2xl:w-44  "
              src={logo16}
              alt="logo16"
            />
            <img
              className="w-52 sm:w-72 md:w-72 lg:w-72 xl:w-72 2xl:w-72  "
              src={logo4}
              alt="logo4"
            />
            <img
              className="w-36 sm:w-52 md:w-52 lg:w-52 xl:w-52 2xl:w-52 "
              src={logo12}
              alt="logo12"
            />
            <img
              className="w-36 sm:w-52 md:w-52 lg:w-52 xl:w-52 2xl:w-52  "
              src={logo14}
              alt="logo14"
            />
            <img
              className="w-28 sm:w-44 md:w-44 lg:w-44 xl:w-44 2xl:w-44  "
              src={logo5}
              alt="logo5"
            />
            <img
              className="w-32 sm:w-52 md:w-52 lg:w-52 xl:w-52 2xl:w-52  "
              src={logo10}
              alt="logo10"
            />
            <img
              className="w-16 sm:w-28 md:w-28 lg:w-28 xl:w-28 2xl:w-28  "
              src={logo7}
              alt="logo7"
            />
            <img
              className="w-28 sm:w-44 md:w-44 lg:w-44 xl:w-44 2xl:w-44  "
              src={logo13}
              alt="logo13"
            />
            <img
              className="w-28 sm:w-44 md:w-44 lg:w-44 xl:w-44 2xl:w-44  "
              src={logo8}
              alt="logo8"
            />
            <img
              className="w-32 sm:w-48 md:w-48 lg:w-48 xl:w-48 2xl:w-48  "
              src={logo11}
              alt="logo11"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Brands;
