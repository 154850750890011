import React from "react";
import { useState } from "react";
import { FaXmark } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";

const AsCreator = () => {
  const [user, setUser] = useState({
    name: "",
    email: "",
    mobile: "",
    instagram: "",
    instafollower: "",
    youtube: "",
    youtubesubs: "",
    username: "",
  });

  const [showPopup, setShowPopup] = useState(false);

  const handleInputs = (e) => {
    let id, value;
    id = e.target.id;
    value = e.target.value;
    setUser({ ...user, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform form submission logic
    const formData = new FormData();
    formData.append("Name", user.name);
    formData.append("Email", user.email);
    formData.append("Mobile", user.mobile);
    formData.append("Instagram", user.instagram);
    formData.append("Instafollower", user.instafollower);
    formData.append("Youtube", user.youtube);
    formData.append("Youtubesubs", user.youtubesubs);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_CREATOR_CALLBACK_URI}`,
        {
          method: "POST",
          body: formData,
        }
      );
      if (response.ok) {
        setShowPopup(true);
        // // Redirect to localhost:3000
        // window.location.href = "http://localhost:3000";
        setUser({
          name: "",
          email: "",
          mobile: "",
          instagram: "",
          instafollower: "",
          youtube: "",
          youtubesubs: "",
        });
      } else {
        // Handle error
        console.error("Form submission failed");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const closePopup = () => {
    setShowPopup(false);
    // Redirect to localhost:3000 after closing popup (optional)
    window.location.href = "https://vendymedia.com";
  };

  return (
    <>
      <div className="">
        <form
          method="POST"
          className=" space-y-8 p-8 w-[26rem]"
          onSubmit={handleSubmit}
        >
          <div>
            <input
              type="text"
              name="Name"
              id="name"
              value={user.name}
              onChange={handleInputs}
              className="block p-5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              placeholder="What's Your Name"
              required
            />
          </div>
          <div>
            <input
              type="email"
              name="Email"
              id="email"
              value={user.email}
              onChange={handleInputs}
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              placeholder="What's Your Email"
              required
            />
          </div>
          <div>
            <input
              type="number"
              name="Mobile"
              value={user.mobile}
              onChange={handleInputs}
              id="mobile"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
              placeholder="What's Your Mobile Number"
              required
            />
          </div>
          <div className="flex flex-row items-center justify-between ">
            <div className="mr-2 w-20 h-24 px-3 py-16 fill-gray-400 shadow-sm flex flex-row text-gray-500 items-center justify-center  bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500  ">
              <FaInstagram
                style={{ width: "50", height: "50", fill: "#9CA3AF" }}
              />
            </div>
            <div className="space-y-2 w-60  sm:w-72 md:w-72 lg:w-72 xl:w-72 2xl:w-72">
              <input
                type="text"
                name="Instagram"
                value={user.instagram}
                onChange={handleInputs}
                id="instagram"
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-5 px-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                placeholder="What's Your Instagram Username"
              />
              <input
                type="text"
                name="Instafollower"
                value={user.instafollower}
                onChange={handleInputs}
                id="instafollower"
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-5 px-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                placeholder="What's Your Instagram Followers"
              />
            </div>
          </div>
          <div className="flex flex-row  items-center justify-between">
            <div className="mr-2  w-20 h-24  px-3 py-16  shadow-sm flex flex-row text-gray-500 items-center justify-center gap-2  bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500  ">
              <FaYoutube
                style={{ width: "50", height: "50", fill: "#9CA3AF" }}
              />
            </div>
            <div className="space-y-2 w-60  sm:w-72 md:w-72 lg:w-72 xl:w-72 2xl:w-72">
              <input
                type="text"
                name="Youtube"
                value={user.youtube}
                onChange={handleInputs}
                id="youtube"
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-5 px-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                placeholder="What's Your Youtube Channel Name"
              />
              <input
                type="text"
                name="Youtubesubs"
                value={user.youtubesubs}
                onChange={handleInputs}
                id="youtubesubs"
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-5 px-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                placeholder="What's Your Youtube Subscribers"
              />
            </div>
          </div>

          <button
            type="submit"
            className="button  font-medium w-full h-12 rounded-lg text-sm px-10 py-3 me-2 mb-2 bg-gradient-to-tl  from-purple-500 to-purple-800   text-center text-white  bg-primary-700  hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 "
          >
            REQUEST CALLBACK
          </button>
        </form>
        {/* Modal Popup */}
        {showPopup && (
          <div className="popup-overlay fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none">
            <div className="popup-content relative w-96 bg-green-50 rounded-lg shadow-lg focus:outline-none">
              <button
                className="close-button absolute top-0 right-0 m-4 text-gray-500 hover:text-gray-800"
                onClick={closePopup}
              >
                <FaXmark
                  className=""
                  style={{
                    fontSize: "medium",
                    fontWeight: "light",
                    border: "1.5px solid red",
                    borderRadius: "100%",
                    color: "red",
                    padding: "1",
                    width: "20px",
                    height: "20px",
                  }}
                />
              </button>
              <div className="p-8 popup-text">
                <h2 className="text-lg font-medium text-green-700 mb-4">
                  Callback Request Submitted
                </h2>
                <p className="text-sm text-gray-600">
                  Thank you for submitting your callback request. We will get
                  back to you shortly.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AsCreator;
