import React, { useState } from "react";
import team from "../../../assets/team5.jpg";
import AsBrand from "../asBrand";
import AsCreator from "../asCreator";

const CreatorCallback = () => {
  const [isChecked, setIsChecked] = useState(true); // Initially set to false for AsBrand component

  const handleBrandClick = () => {
    setIsChecked(false); // Set isChecked to false when "For Brand" is clicked
  };

  const handleCreatorClick = () => {
    setIsChecked(true); // Set isChecked to true when "For Creator" is clicked
  };

  return (
    <>
      <section
        id="connect"
        className="flex flex-row justify-center items-center  dark:bg-gray-900"
      >
        <div
          className="items-center w-full  relative z-0 flex justify-center flex-col"
          style={{ height: "60rem" }}
        >
          <img
            className="w-full opacity-90 h-[60rem] mx-auto object-cover md:w-full md:h-[60rem] lg:w-full lg:h-[60rem]"
            src={team}
            alt="man"
            loading="lazy"
            width="1000"
            height="1000"
          />
          <div className="absolute z-10 flex items-center flex-col gap-10 p-10">
            <div className="mt-8 flex flex-col sm:flex-col md:flex-col lg:flex-row xl:flex-row 2xl:flex-row gap-6">
              <div
                className="relative rounded-md flex items-center justify-center bg-purple-800 py-4 px-10 font-medium hover:scale-110 hover:shadow-lg uppercase hover:bg-white hover:text-purple-800 text-white transition-colors cursor-pointer"
                onClick={handleBrandClick}
              >
                <div>I'M A BRAND</div>
              </div>
              <div
                className="relative rounded-md flex items-center justify-center bg-black py-4 px-10 font-medium uppercase hover:scale-110 hover:shadow-lg hover:bg-white hover:text-black text-white transition-colors cursor-pointer"
                onClick={handleCreatorClick}
              >
                <div>I'M AN INFLUENCER</div>
              </div>
            </div>
            {isChecked === false ? <AsBrand /> : <AsCreator />}
          </div>
        </div>
      </section>
    </>
  );
};

export default CreatorCallback;
