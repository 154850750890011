import React from "react";
import { FaUserTie } from "react-icons/fa6";
import { FaMobileScreenButton } from "react-icons/fa6";
import { FaCameraRetro } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaBusinessTime } from "react-icons/fa";

const Services = () => {
  return (
    <section className=" ">
      <div className="container mx-auto  mb-20">
        <div className="-mx-4  flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto py-16 text-center ">
              <span className=" mb-2 block  text-primary text-purple-500 text-base font-semibold">
                OUR SERVICES
              </span>
              <h2 className="w-full font-semibold text-5xl sm:text-5xl md:text-6xl lg:text-6xl xl:text-6xl 2xl:text-6xl text-gray-800 leading-[1.2] ">
                What We Offer
              </h2>
              <p className=" px-8  mt-8 text-base text-gray-400 text-body-color dark:text-dark-6">
                A range of services customized to your needs, guaranteeing
                effectiveness,
                <br /> efficiency, and client satisfaction.
              </p>
            </div>
          </div>
        </div>
        <div className="-mx-4  px-10 flex flex-wrap">
          <div data-aos="fade-left" className="w-full px-4 md:w-1/2 lg:w-1/3">
            <div className="contain mb-9 rounded-[8px] bg-white p-10 shadow-2  dark:bg-dark-2 md:px-7 xl:px-10">
              <div className="icons mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl bg-primary">
                <div
                  className="icon1"
                  style={{ border: "1.5px solid #8A2BE2", borderRadius: "50%" }}
                >
                  <FaMobileScreenButton
                    className="icon1"
                    style={{
                      color: "#8A2BE2",
                      padding: "20px",
                      width: "70px",
                      height: "70px",
                    }}
                  />
                </div>
              </div>
              <h4 className="head mb-[14px] text-2xl font-semibold text-dark dark:text-white">
                <span className="whitespace-normal">
                  Social Media Advertising
                </span>
              </h4>
              <p className="text text-gray-400 text-base dark:text-dark-6 ">
                Promoting brands on social platforms for targeted reach.
              </p>
            </div>
          </div>
          <div data-aos="fade-left" className="w-full px-4 md:w-1/2 lg:w-1/3">
            <div className="contain mb-9 rounded-[8px] bg-white p-10 shadow-2  dark:bg-dark-2 md:px-7 xl:px-10">
              <div className="icons mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl bg-primary">
                <div
                  className="icon1"
                  style={{ border: "1.5px solid #8A2BE2", borderRadius: "50%" }}
                >
                  <FaUserTie
                    className="icon1"
                    style={{
                      color: "#8A2BE2",
                      padding: "20px",
                      width: "70px",
                      height: "70px",
                    }}
                  />
                </div>
              </div>
              <h4 className="head mb-[14px] text-2xl font-semibold text-dark dark:text-white">
                <span className="whitespace-normal">
                  Influencer Matchmaking
                </span>
              </h4>
              <p className="text text-gray-400 text-base dark:text-dark-6 ">
                Connecting brands with ideal influencers for impactful
                partnerships.
              </p>
            </div>
          </div>
          <div data-aos="fade-left" className="w-full px-4 md:w-1/2 lg:w-1/3">
            <div className="contain mb-9 rounded-[8px] bg-white p-10 shadow-2  dark:bg-dark-2 md:px-7 xl:px-10">
              <div className="icons mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl bg-primary">
                <div
                  className="icon1"
                  style={{ border: "1.5px solid #8A2BE2", borderRadius: "50%" }}
                >
                  <FaBusinessTime
                    className="icon1"
                    style={{
                      color: "#8A2BE2",
                      padding: "20px",
                      width: "70px",
                      height: "70px",
                    }}
                  />
                </div>
              </div>
              <h4 className="head mb-[14px] text-2xl font-semibold text-dark dark:text-white">
                <span className="whitespace-normal">Campaign Management</span>
              </h4>
              <p className="text text-gray-400 text-base dark:text-dark-6 ">
                Guiding campaigns from planning to execution for successful
                outcomes.
              </p>
            </div>
          </div>
          <div data-aos="fade-left" className="w-full px-4 md:w-1/2 lg:w-1/3">
            <div className="contain mb-9 rounded-[8px] bg-white p-10 shadow-2  dark:bg-dark-2 md:px-7 xl:px-10">
              <div className="icons mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl bg-primary">
                <div
                  className="icon1"
                  style={{ border: "1.5px solid #8A2BE2", borderRadius: "50%" }}
                >
                  <FaInstagram
                    className="icon1"
                    style={{
                      color: "#8A2BE2",
                      padding: "20px",
                      width: "70px",
                      height: "70px",
                    }}
                  />
                </div>
              </div>
              <h4 className="head mb-[14px] text-2xl font-semibold text-dark dark:text-white">
                <span className="whitespace-normal">Meta Marketing</span>
              </h4>
              <p className="text text-gray-400 text-base dark:text-dark-6 ">
                Encompasses diverse strategies like WhatsApp, email, SMS, and
                call marketing for comprehensive outreach and engagement.
              </p>
            </div>
          </div>
          <div data-aos="fade-left" className="w-full px-4 md:w-1/2 lg:w-1/3">
            <div className="contain mb-9 rounded-[8px] bg-white p-10 shadow-2  dark:bg-dark-2 md:px-7 xl:px-10">
              <div className="icons mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl bg-primary">
                <div
                  className="icon1"
                  style={{ border: "1.5px solid #8A2BE2", borderRadius: "50%" }}
                >
                  <FaCameraRetro
                    className="icon1"
                    style={{
                      color: "#8A2BE2",
                      padding: "20px",
                      width: "70px",
                      height: "70px",
                    }}
                  />
                </div>
              </div>
              <h4 className="head mb-[14px] text-2xl font-semibold text-dark dark:text-white">
                <span className="  whitespace-normal">Content Creation</span>
              </h4>
              <p className="text text-gray-400 text-base dark:text-dark-6 ">
                Expert guidance for organic audience growth through strategic
                content creation.
              </p>
            </div>
          </div>
          <div data-aos="fade-left" className="w-full px-4 md:w-1/2 lg:w-1/3">
            <div className="contain mb-9 rounded-[8px] bg-white py-10 pl-10 pr-10 shadow-2  dark:bg-dark-2 md:pl-7 xl:pl-10 xl:pr-0">
              <div className=" icons mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl bg-primary">
                <div
                  className="icon1"
                  style={{ border: "1.5px solid #8A2BE2", borderRadius: "50%" }}
                >
                  <FaEdit
                    className="icon1"
                    style={{
                      color: "#8A2BE2",
                      padding: "20px",
                      width: "70px",
                      height: "70px",
                    }}
                  />
                </div>
              </div>
              <h4 className="head mb-[14px] text-2xl font-semibold text-dark dark:text-white">
                <span className=" whitespace-normal">
                  Social Media Management
                </span>
              </h4>
              <p className=" text text-gray-400 text-base dark:text-dark-6 ">
                Guiding campaigns from planning to execution for successful
                outcomes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
