import React from "react";
import { FaCheck } from "react-icons/fa6";

const StayOrganised = () => {
  return (
    <>
      <div
        data-aos="fade-up"
        className="py-20 px-10 flex flex-col justify-center items-center"
      >
        <div className=" py-5 w-full mx-auto org flex items-center justify-center flex-col  text-center">
          <h2 className="w-full diff font-semibold  text-gray-800 text-[2.63rem] sm:text-5xl md:text-6xl lg:text-6xl xl:text-6xl 2xl:text-6xl">
            Our Differentiators
          </h2>
          <p className="apart text-base text-gray-400 ">
            Points which sets us apart?
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 mt-10  w-fit grid-rows-3 gap-10 gap-x-2">
          <div className=" w-fit flex flex-row items-center ">
            <FaCheck
              className="w-[60px] sm:w-[60px] md:w-[70px] lg:w-[70px] xl:w-[70px] 2xl:w-[70px] h-[60px] sm:h-[60px] md:h-[70px] lg:h-[70px] xl:h-[70px] 2xl:h-[70px]"
              style={{
                fontSize: "medium",
                fontWeight: "bold",
                color: "lightgreen",
                padding: "15",
              }}
            />

            <div className="font-semibold text-gray-800  text-xl sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-2xl">
              Affordable Rates
            </div>
          </div>
          <div className="  w-fit  flex flex-row items-center  ">
            <FaCheck
              className="w-[60px] sm:w-[60px] md:w-[70px] lg:w-[70px] xl:w-[70px] 2xl:w-[70px] h-[60px] sm:h-[60px] md:h-[70px] lg:h-[70px] xl:h-[70px] 2xl:h-[70px]"
              style={{
                fontSize: "medium",
                fontWeight: "bold",
                color: "lightgreen",
                padding: "15",
              }}
            />

            <div className="font-semibold text-gray-800  text-xl sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-2xl">
              Handpicked Discovery
            </div>
          </div>
          <div className="  w-fit  flex flex-row items-center ">
            <FaCheck
              className="w-[60px] sm:w-[60px] md:w-[70px] lg:w-[70px] xl:w-[70px] 2xl:w-[70px] h-[60px] sm:h-[60px] md:h-[70px] lg:h-[70px] xl:h-[70px] 2xl:h-[70px]"
              style={{
                fontSize: "medium",
                fontWeight: "bold",
                color: "lightgreen",
                padding: "15",
              }}
            />

            <div className="font-semibold text-gray-800  text-xl sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-2xl">
              Payment Simplification
            </div>
          </div>
          <div className=" w-fit  flex flex-row items-center ">
            <FaCheck
              className="w-[60px] sm:w-[60px] md:w-[70px] lg:w-[70px] xl:w-[70px] 2xl:w-[70px] h-[60px] sm:h-[60px] md:h-[70px] lg:h-[70px] xl:h-[70px] 2xl:h-[70px]"
              style={{
                fontSize: "medium",
                fontWeight: "bold",
                color: "lightgreen",
                padding: "15",
              }}
            />

            <div className="font-semibold text-gray-800 text-xl sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-2xl">
              Contract Streamlining
            </div>
          </div>
          <div className="  w-fit  flex flex-row items-center ">
            <FaCheck
              className="w-[60px] sm:w-[60px] md:w-[70px] lg:w-[70px] xl:w-[70px] 2xl:w-[70px] h-[60px] sm:h-[60px] md:h-[70px] lg:h-[70px] xl:h-[70px] 2xl:h-[70px]"
              style={{
                fontSize: "medium",
                fontWeight: "bold",
                color: "lightgreen",
                padding: "15",
              }}
            />

            <div className="font-semibold text-gray-800  text-xl sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-2xl">
              Informed Choices
            </div>
          </div>
          <div className="  w-fit  flex flex-row items-center ">
            <FaCheck
              className="w-[60px] sm:w-[60px] md:w-[70px] lg:w-[70px] xl:w-[70px] 2xl:w-[70px] h-[60px] sm:h-[60px] md:h-[70px] lg:h-[70px] xl:h-[70px] 2xl:h-[70px]"
              style={{
                fontSize: "medium",
                fontWeight: "bold",
                color: "lightgreen",
                padding: "15",
              }}
            />

            <div className="font-semibold text-gray-800  text-xl sm:text-xl md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-2xl">
              Fullfilment Challenges
            </div>
          </div>
        </div>
      </div>
      <hr className="w-8/12 ml-auto mr-auto mb-20" />
    </>
  );
};

export default StayOrganised;
